@import url("../../CustomStyle/sassStyles.css");

body {
  background-color: var(--primary-shade4) !important;
}

.container {
  width: 100% !important;
}

.language {
  color: white;
  background: none;
  border: none;
}

.language:hover > option,
.language option:hover,
.language option:active,
.language option:focus {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.login-panel {
  width: 400px;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}

.thankyou-container {
  width: 600px;
  padding: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}

.instruct-head {
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 20px;
}

/* 
.agreement-container {
  width: 878px;
  padding: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
} */

.agreement-body {
  margin: 20px;
  padding: 20px;
  height: 300px;
  font-size: 14px;
  overflow-y: auto;
}

.sub-heads {
  font-size: 16px;
  color: #600097;
}

.agree-checkbox {
  margin: 20px;
  padding: 20px;
  font-weight: 600;
}

.panel-title {
  color: #600097;
}

/* .panel-body { */
/* margin: 20px;
    padding: 20px; */
/* border: 2px solid #febf00; */
/* } */
.form-group {
  margin: 20px;
}

.form-group label {
  font-weight: 500;
}

.form-control {
  margin-top: 8px;
}

.form-control:focus {
  box-shadow: none;
}

input {
  border: 2px solid #e8e8e8 !important;
}

.button-container {
  margin-top: 30px;
  text-align: center;
}

.eula-button-container {
  margin-top: 30px;
  text-align: right;
}

.submit-btn {
  background-color: #600097;
  border: 1px solid #46006e;
  color: #ffffff;
  border-radius: 4px;
  margin-right: 10px;
}

.submit-btn:hover,
.submit-btn:active,
.submit-btn:focus {
  background-color: #46006e !important;
  border: 1px solid #46006e;
  color: #ffffff !important;
  border-radius: 4px;
  margin-right: 10px;
}

.cancel-btn,
.cancel-btn:hover,
.cancel-btn:active,
.cancel-btn:focus {
  background-color: #e9e9e9 !important;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}

.record-img:hover {
  content: url("../../assets/Record\ Icon\ -\ On\ Hover.png");
}

progress {
  height: 4px;
  width: 100%;
  border: none;
  display: block !important;
}

progress::-webkit-progress-bar {
  background-color: #c2c2c2;
}

progress::-webkit-progress-value {
  background-color: #ffa200;
}

.progress-orange::-webkit-progress-value {
  background-color: #ffa200;
}

#questionGreen::-webkit-progress-value {
  background-color: #00c22d;
}

#questionOrange::-webkit-progress-value {
  background-color: #ffa200;
}

#questionRed::-webkit-progress-value {
  background-color: #ff0000;
}

.questionGreen {
  background: #46006e !important;
}

.modal-dialog {
  max-width: 700px;
  width: 670px;
  margin: 60px auto;
  border-radius: 20px;
  box-shadow: 0px 0px 30px #3800581a;
}

.modal-dialog {
  width: 600px;
  margin: 8% auto !important;
}

.modal-header,
.modal-body {
  padding: 0px !important;
}

.navbar {
  margin-bottom: 0px !important;
}

.navbar-brand {
  padding: 7px 0px !important;
}

@media only screen and (max-width: 600px) {
  .mobile-view {
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.next-button-hover {
  background: #00c22d !important;
  cursor: pointer !important;
}

.stop-button-hover {
  background: #ff0000 !important;
  cursor: pointer !important;
  margin: 5px;
  border-radius: 20px 8px 8px 20px;
  color: white;
  border: none;
}

.stop-button-hover:hover {
  background: #f14949 !important;
}

.stop-button-hover:disabled {
  cursor: not-allowed !important;
}

.next-button-hover {
  background: #00c22d !important;
  cursor: pointer;
  border: none;
  margin: 5px;
  color: white;
  border-radius: 8px 20px 20px 8px;
}

.next-button-hover:disabled {
  cursor: not-allowed !important;
  /* Change cursor to not-allowed when button is disabled */
}

.next-button-hover:hover {
  background: #3bb557 !important;
  cursor: pointer;
}

.not-allowed-cursor {
  cursor: not-allowed !important;
}

.interview-text-input {
  border-radius: 10px;
  border: 1px solid var(--primary-color);
  width: 100%;
  outline: none;
  padding: 7px;
  margin-bottom: 30px;
  resize: none;
  /*remove the resize handle on the bottom right*/
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
}

.interview-text-input::placeholder {
  font-size: 14px;
}

.interview-question-container {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between !important;
}

.display-question {
  margin-top: 20px;
  height: 43vh;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 15px;
}

.display-question-show {
  height: 85vh;
  overflow-x: auto;
  overflow-y: auto;
}

.main-interview-container {
  width: 100%;
  display: flex;
  height: 95vh;
}

.interview-main-question-container {
  width: 25%;
  /* height:100%; */
  display: flex;
  justify-content: center;
  padding: 0;
  background: #fff;
}

.interview-video-container {
  width: 75%;
  display: flex;
  justify-content: center;
  padding-inline: 10px;
}

@media only screen and (max-width: 992px) {
  .main-interview-container {
    flex-direction: column;
  }

  .interview-main-question-container {
    width: 100%;
  }

  .interview-video-container {
    width: 100%;
  }

  .display-question {
    font-size: 17px;
    margin-bottom: 10px;
  }
}

.video-container {
  width: 100vw;
  height: 100%;
  position: relative;
  /* padding-inline:10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.video-preview {
  width: 100%;
  aspect-ratio: 2/1;
  background-color: #ffffff;
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
  top: 65px;
  height: 77vh !important;
}

.question-label {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 20px;
}

.alert-modal-label {
  color: var(--primary-color);
}

.progress,
.progress-stacked {
  height: 5px !important;
  width: 210px;
}

.finish-interview-btn {
  border: "none";
  margin: 5;
  background: "#00C22D80 0% 0% no-repeat padding-box";
  color: "white";
  border-radius: "8px 20px 20px 8px";
}

.ques-reading-container {
  height: 100vh;
  display: flex;
  justify-content: center;
}

.ques-reading-card {
  height: 80vh;
  width: 60%;
  padding: 15px;
  /* margin-bottom: 50px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}

.ques-reading-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 75%;
}

.question-show-container {
  height: 75%;
  overflow-y: auto;
}

.start-answering-btn {
  background-color: var(--primary-btn);
  height: 40px;
  margin-top: 10px;
}
