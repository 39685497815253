/* @import url('../../../CustomStyle/sassStyles.css'); */

.navbar {
  margin-bottom: 0px !important;
}

.container-nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
  align-items: center;
}

.navbar-head {
  background-color: var(--primary-color);
}
