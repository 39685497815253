.confirm-btn {
    width: 110px;
    height: 30px;
    background: #77DD77 0% 0% no-repeat padding-box;
    border: #77DD77;
    border-radius: 5px;
    opacity: 1;
    color: white;
}

.confirm-btn:hover {
    background: #56AE56 0% 0% no-repeat padding-box;
    border: #56AE56;
}


.otp-cancel-btn {
    width: 110px;
    height: 30px;
    background: #8d8787 0% 0% no-repeat padding-box;
    border: #8d8787;
    border-radius: 5px;
    opacity: 1;
    color: white;
}

.otp-cancel-btn:hover {
    background: #8d8787  0% 0% no-repeat padding-box;
    border: #8d8787 ;
}

.resend-otp-sub {
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
    margin-top: 30px;
  }

  .signin-label {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 0px;
    color: #600097;
    margin-top: 10px;
  }

  .custom-toast-error {
    display: flex;
    text-align: left;
  }