.instruction-main-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instruction-inner-container {
  filter: drop-shadow(0 0 30px #3800581a);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 20px;
}

.read-instruction-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.read-instruction-text {
  color: #ff0000;
  font-size: 20px;
  font-weight: 500;
}

.instructions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.start-interview-btn:disabled {
  cursor: not-allowed !important;
}

.start-interview-btn-enabled:hover .start-interview-btn-dot-enabled {
  background-color: #00c22d !important;
}

.start-interview-btn-enabled:hover {
  background-color: #00c22d !important;
  cursor: pointer;
}

.start-interview-btn-circle {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ffffff;
  margin-left: -8px;
  /* filter: drop-shadow(0 0 10px #0000001a); */
  box-shadow: 0 0 3px 3px #3800581a;
  text-align: center;
  position: relative;
}

.start-interview-btn-dot {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  height: 16px;
  width: 16px;
  background-color: #ff0000;
  border-radius: 50%;
}

.start-interview-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
