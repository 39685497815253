.test-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.webcam-mic-text {
  font-size: 30px;
  color: var(--primary-color);
  margin: 10px;
}

.test-video-container {
  /* height: 65vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 800px;
  background-color: #ffffff;
  border-radius: 10px;
}

.test-video {
  height: 60vh !important;
  width: 800px !important;
  aspect-ratio: 2/1;
  object-fit: cover;
  border-radius: 10px;
}

.alert-text,
.success-text {
  font-size: 20px;
  color: #ff0000;
  text-transform: uppercase;
  font-weight: 500;
  /* margin: 10px; */
}

.success-text {
  color: #00c22d;
}

.info-text {
  font-size: 14px;
  margin-bottom: 10px;
  padding-inline: 100px;
  text-align: center;
  color: #333333;
  font-weight: 400;
}

.read-text {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-inline: 100px;
  text-align: center;
  color: #333333;
}

.test-btn-container {
  margin-top: 10px;
}

.test-screen-btn {
  padding-block: 5px;
  width: 150px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 7px;
  border: none;
  cursor: pointer;
}

.start-testing-btn {

  background-color: var(--primary-btn);
}

.listening-btn {
  position: relative;
  background-color: var(--primary-btn);
  overflow: hidden;
  color: white;
}

.listening-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%;
  width: 100%;
  background-color: var(--secondary-btn)
}

.listening-btn span {
  z-index: 1;
  position: relative;
}

.listening-btn.btn-progress::before {
  animation: layer 10s ease-in-out forwards;
  color: #ffffff;
}

@keyframes layer {
  100% {
    left: 0%;
  }
}

.retry-btn {
  background-color: #ff0000;
}

.success-btn {
  background-color: #00c22d;
}

.speech-compare-btn {
  visibility: hidden;
}