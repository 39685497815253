body {
    background-color: #F8EBFF !important;
  }
  
  .container {
    width: 100% !important;
  }
 
  .login-card {
    width: 400px;
    padding: 30px;    
    background-color: #ffffff;
    box-shadow: 0px 0px 30px #3800581A;
    border-radius: 20px;
  }
  .panel-title {
    color: var(--primary-color);
  }
  
  .form-group {
    margin: 20px;
  }
 
  .form-control {
    margin-top: 8px;
  }
  .form-control:focus {
    box-shadow: none;
  }
  input {
    border: 2px solid #e8e8e8 !important;
  }
  .button-container {
    margin-top: 30px;
    text-align: center;
  }
  .submit-btn {
    background-color:  var(--primary-btn) !important;
    border: 1px solid  var(--primary-btn)  !important;
    color: #FFFFFF  !important;
    border-radius: 4px;
    margin-right: 10px;
  }
  .submit-btn:hover, .submit-btn:active, .submit-btn:focus {
    background-color: var(--primary-btn) !important;
    border: 1px solid  var(--primary-btn);
    color: #FFFFFF !important;
    border-radius: 4px;
    margin-right: 10px;
  } 
  .cancel-btn, .cancel-btn:hover, .cancel-btn:active, .cancel-btn:focus {
    background-color: #E9E9E9 !important;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
  }
.navbar { 
  margin-bottom: 0px !important; 
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.device-warning {
  background-color: #f8d7da;
  color: #721c24;
  padding: 15px;
  border-radius: 8px;
  margin: 10px 0;
  text-align: center;
  border: 1px solid #f5c6cb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  animation: fadeIn 1s ease-in-out;
}

.warning-icon {
  font-size: 24px;
  margin-right: 10px;
}

.warning-message {
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
