@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital@1&display=swap'); */

body {
  margin: 0;
  font-family: "Roboto", Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
  height: 100vh;
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
  html {
    /* transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0; */
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(165, 165, 165);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}

.panel-heading{
  text-align: center;
}