@import url("../../CustomStyle/sassStyles.css");

body {
  background-color: var(--primary-shade4) !important;
  overflow: hidden;
}

.licence-main-container {
  height: 100vh;
}

.agreement-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.agreement-container {
  height: max-content;
  /* margin: auto; */
  /* align-items: center !important; */
  /* overflow: hidden; */
  /* justify-content: center; */
  width: 75%;
  padding: 15px;
  /* margin-bottom: 50px; */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}

.agreement-body {
  height: 55vh !important;
}

.container {
  width: 100% !important;
}

.language {
  color: white;
  background: none;
  border: none;
}

.language:hover > option,
.language option:hover,
.language option:active,
.language option:focus {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.login-panel {
  width: 400px;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}

.instruct-container {
  background-color: #ffffff;
  /* padding: 20px; */
}

.instruct-head {
  color: #ff0000;
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-bottom: 20px;
}

.instruct-container ol li {
  margin-top: 10px;
}

.eula-container {
  width: 80%;
  padding: 30px;
  /* margin-bottom: 50px;   */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581a;
  border-radius: 20px;
}

.eula-body {
  /* margin: 20px; */
  padding: 20px;
  height: 300px;
  font-size: 14px;
  overflow-y: scroll;
  font-weight: 500;
  color: #333333;
}

.sub-heads {
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-color);
}

.agree-checkbox-card {
  /* margin: 15px; */
  padding-inline: 20px;
  font-weight: bold;
  color: #050505;
}

.panel-title {
  color: var(--primary-color);
}

/* .panel-body { */
/* margin: 20px;
  padding: 20px; */
/* border: 2px solid #febf00; */
/* } */
.form-group {
  margin: 20px;
}

.form-group label {
  font-weight: 500;
}

.form-control {
  margin-top: 8px;
}

.form-control:focus {
  box-shadow: none;
}

input {
  border: 2px solid #e8e8e8 !important;
}

.button-container {
  margin-top: 30px;
  text-align: center;
}

.eula-buttons-container {
  /* margin-top: 30px; */
  text-align: right;
}

.submit-btn {
  background-color: var(--primary-btn);
  border: 1px solid var(--primary-btn);
  color: #ffffff;
  border-radius: 4px;
  margin-right: 10px;
}

.submit-btn:hover,
.submit-btn:active,
.submit-btn:focus {
  background-color: var(--primary-btn) !important;
  border: 1px solid var(--primary-btn);
  color: #ffffff !important;
  border-radius: 4px;
  margin-right: 10px;
}

.cancel-btn,
.cancel-btn:hover,
.cancel-btn:active,
.cancel-btn:focus {
  background-color: #e9e9e9 !important;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
}

/* .record-img:hover {
  content: url("Record Icon - On Hover.png");
}  */
progress {
  height: 4px;
  width: 100%;
  border: none;
  display: block !important;
}

progress::-webkit-progress-bar {
  background-color: #c2c2c2;
}

progress::-webkit-progress-value {
  background-color: #00c22d;
}

.modal-dialog {
  max-width: 700px;
  width: 670px;
  margin: 60px auto;
  border-radius: 20px;
  box-shadow: 0px 0px 30px #3800581a;
}

.modal-dialog {
  width: 600px;
  margin: 8% auto !important;
}

.modal-header,
.modal-body {
  padding: 0px !important;
}

.navbar {
  margin-bottom: 0px !important;
}

.navbar-brand {
  padding: 7px 0px !important;
}

.agree-btn {
  margin-right: 10px;
  color: "#A1A1A1";
  background: "#e9e9e9" !important;
  padding: 6px 12px;
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  opacity: 1 !important;
}

.agree-btn:disabled {
  background: "#e9e9e9" !important;
  color: "#A1A1A1";
  opacity: 1 !important;
}
