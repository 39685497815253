/* body {
    background-color: #F8EBFF !important;
  }
  .container-nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 20px;
  }
  .container {
    width: 100% !important;
  }
  .language {
    color: white;
    background: none;
    border: none;
  }
  .language:hover > option,
  .language option:hover,
  .language option:active,
  .language option:focus {
    background-color: #ffffff;
    color: rgb(0, 0, 0);
  }
  .login-panel {
    width: 400px;
    padding: 30px;    
    background-color: #ffffff;
    box-shadow: 0px 0px 30px #3800581A;
    border-radius: 20px;
  }
  .thankyou-container {    
    width: 600px;
    padding: 30px;
    margin-bottom: 50px;  
    background-color: #ffffff;
    box-shadow: 0px 0px 30px #3800581A;
    border-radius: 20px;
  }
  .instruct-container {
    background-color: #ffffff;
    padding: 20px;
  }
  .instruct-head {
    color: #FF0000;
    display: flex;
    align-items: center;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .instruct-container ol li{
    margin-top: 10px;
  }
  .agreement-container {
    width: 878px;
    padding: 30px;
    margin-bottom: 50px;  
    background-color: #ffffff;
    box-shadow: 0px 0px 30px #3800581A;
    border-radius: 20px;
  }
  .agreement-body {    
    margin: 20px;
    padding: 20px;
    height: 300px;
    font-size: 14px;
    overflow-y: scroll;
  }
  .sub-heads {
    font-size: 16px;
    color: #600097;
  }
  .agree-checkbox {   
    margin: 20px;
    padding: 20px;
    font-weight: 600;
  }
  .panel-title {
    color: #600097;
  }
  .form-group {
    margin: 20px;
  }
  .form-group label {
    font-weight: 500;
  }
  .form-control {
    margin-top: 8px;
  }
  .form-control:focus {
    box-shadow: none;
  }
  input {
    border: 2px solid #e8e8e8 !important;
  }
  .button-container {
    margin-top: 30px;
    text-align: center;
  }
  .eula-button-container {
    margin-top: 30px;
    text-align: right;

  }
  .submit-btn {
    background-color:  #600097;
    border: 1px solid  #46006E;
    border-radius: 0px;
    color: #FFFFFF;
    border-radius: 4px;
    margin-right: 10px;
  }
  .submit-btn:hover, .submit-btn:active, .submit-btn:focus {
    background-color: #46006E !important;
    border: 1px solid  #46006E;
    border-radius: 0px;
    color: #FFFFFF !important;
    border-radius: 4px;
    margin-right: 10px;
  } 
  .cancel-btn, .cancel-btn:hover, .cancel-btn:active, .cancel-btn:focus {
    background-color: #E9E9E9 !important;
    border: 1px solid #C2C2C2;
    border-radius: 4px;
  }
  progress {
    height: 4px;
    width: 100%;
    border: none;   
    display: block !important;
 }
 progress::-webkit-progress-bar {
    background-color: #C2C2C2;
 }
 progress::-webkit-progress-value {
    background-color: #00C22D;
 }
 
 .modal-dialog {
  max-width: 700px;
  width: 670px;
  margin: 60px auto;
  border-radius: 20px;
  box-shadow: 0px 0px 30px #3800581A;
}
.modal-dialog {
  width: 600px;
  margin: 8% auto !important;
}
.modal-header, .modal-body {
  padding: 0px !important;
}
.navbar { 
  margin-bottom: 0px !important; 
}
.navbar-brand {
  padding: 7px 0px !important;
} */

.thankyou-screen-container {    
  width: 600px;
  padding: 30px;
  /* margin-bottom: 50px;   */
  background-color: #ffffff;
  box-shadow: 0px 0px 30px #3800581A;
  border-radius: 20px;
}